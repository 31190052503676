import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tm_dropdown_item, {
      label: "View contact",
      icon: "visibility",
      to: { name: 'base.contacts.contactDetails', params: { id: '1' } }
    }, null, 8, ["to"]),
    _createVNode(_component_tm_dropdown_item, {
      label: _ctx.isSubscribed ? 'Unsubscribe contact' : 'Resubscribe contact',
      icon: _ctx.isSubscribed ? 'do_not_disturb_on' : 'check_circle',
      onClick: _ctx.handleSubscribe
    }, null, 8, ["label", "icon", "onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      label: _ctx.isBlocked ? 'Unblock contact' : 'Block contact',
      icon: _ctx.isBlocked ? 'check_circle_outline' : 'block',
      onClick: _ctx.handleBlock
    }, null, 8, ["label", "icon", "onClick"]),
    _createVNode(_component_tm_dropdown_item, {
      label: "Delete",
      icon: "delete",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDeleteModal(1, _ctx.type)))
    })
  ], 64))
}