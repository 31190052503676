
import { defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import useHistoryCalls from '@/compositions/history/useHistoryCalls'
import useBlockedOpenModal from '@/compositions/contacts/blocked/useBlockedOpenModal'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  props: {
    type: {
      type: String as PropType<'forwarded' | 'outbound' | 'inbound'>,
      default: 'forwarded',
    },
  },
  setup() {
    const { openBlockContactModal, openResubscribeContactModal, openUnsubscribeContactModal } = useContactsOpenModal()
    const { openModalUnblock } = useBlockedOpenModal()
    const { isErrorMode } = useModes()
    const { openDeleteModal } = useHistoryCalls()

    const isSubscribed = ref(!isErrorMode.value)
    const isBlocked = ref(isErrorMode.value)

    const handleSubscribe = () => {
      if (isSubscribed.value) {
        openUnsubscribeContactModal()
      } else {
        openResubscribeContactModal()
      }

      isSubscribed.value = !isSubscribed.value
    }

    const handleBlock = () => {
      if (isBlocked.value) {
        openModalUnblock('John Doe')
      } else {
        openBlockContactModal('John Doe')
      }
      isBlocked.value = !isBlocked.value
    }

    watch(() => isErrorMode.value, (val) => {
      if (val) {
        isSubscribed.value = false
        isBlocked.value = true
      }
    })

    return {
      handleSubscribe,
      handleBlock,
      openDeleteModal,
      isSubscribed,
      isBlocked,
    }
  },
})
